import React from 'react'
import Title from '../Title'
import './detail.less'
import d1 from '../assets/d5/chellon20200411a.jpg'
import d2 from '../assets/d5/chellon20200411b.jpg'
import d3 from '../assets/d5/chellon20200411c.jpg'

import Img from '../Img'

export default props => {

    return <div className="news-detail">
        <Title name="集团人文" />
        <div className="content">
            <h1 className="title">
            天猫养车郑州推介会
            </h1>
            <p className="date">2021.4.11</p>
            <p className="desc">壳隆与天猫养车门店的合作，首次从上海外拓至外省。未来会有更多的落户合作城市与壳隆展开合作。</p>
            <Img src={d1} alt=""/>
            <p className="desc" style={{textAlign: 'center'}}>壳隆天猫养车项目 文艳总 在做项目介绍</p>
            <Img src={d2} alt="" /><br></br>
            <p className="desc" style={{textAlign: 'center'}}>壳隆天猫养车项目 文艳总 在做活动宣贯</p>
            <Img src={d3} alt="" /><br></br>
            <p className="desc" style={{textAlign: 'center'}}>壳隆天猫养车项目 文艳总 史经理在做营销演练</p>
        </div>
    </div>
}