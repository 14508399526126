import React from 'react'
import './style.less'
import C1 from '../assets/car/c1.png'
import C2 from '../assets/car/c2.png'
import C3 from '../assets/car/c3.png'
import C4 from '../assets/car/c4.png'
import Title from '../Title'

export default props => {

    const contents = [
        {
            img: C1,
            title: '渠道销售',
            texts: ['与国内多个汽车服务平台合作，服务覆盖全国2万+家汽修门店']
        },
        {
            img: C2,
            title: '独立品牌',
            texts: ['壳隆自有品牌产品，布局4个大区  20家KAD']
        },
        {
            img: C3,
            title: '物流',
            texts: ['设立总库，并提供物流配送服务，拥有配送至全国任一门店的物流能力。']
        },
        {
            img: C4,
            title: '培训',
            texts: ['基于自身的行业经验，我们为门店提供系统的营销培训和技术培训。']
        },
    ]


    return <div className="business1">
        <Title name="汽车业务" />
        <div className="title"> 
        {/* <span>汽车类</span> */}
        </div>
        {contents.map((val, index) => {
            return <div className="business-block" key={index}>
                {(index + 1) % 2 ? <img src={val.img} alt="" />: null}
                <div className="business-block-content">
                    <h1>{val.title}：</h1>
                    {val.texts.map((text, i) => <p index={i} className="business-block-text">{text}
                    </p>)}
                </div>
                {(index + 1) % 2 ===0  ? <img src={val.img} alt="" /> : null}
            </div>
        })}
    </div>
}
