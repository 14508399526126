import React from 'react'
import './style.less'
import { Route, Switch } from 'react-router-dom'
import Business1 from './Business1'
import Business2 from './Business2'
import Business3 from './Business3'
import Business4 from './Business4'

export default props => {
    return <div className="business">
        <Switch>
            <Route path="/business/car" component={Business1} />
            <Route path="/business/health" component={Business2} />
            <Route path="/business/bigData" component={Business3} />
            <Route path="/business/service" component={Business4} />
        </Switch>
    </div>
}