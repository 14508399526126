import React from 'react'
import Banner from '../Banner'
import './style.less'
import P1 from '../assets/1.jpg'
import P2 from '../assets/2.jpg'
import P3 from '../assets/3.jpg'
import P4 from '../assets/4.jpg'
import h1 from '../assets/home/1.jpg'
import h2 from '../assets/home/2.jpg'
import h3 from '../assets/home/3.jpg'
import h4 from '../assets/home/4.png'
import h5 from '../assets/home/5.png'
import h6 from '../assets/home/6.png'
import h7 from '../assets/home/汽车.svg'
import h8 from '../assets/home/大健康.svg'
import h9 from '../assets/home/大数据及AI.svg'
import Arrow from '../assets/home/直线.svg'

export default props => {
    const banners = [
        <img className="home-banner" src={P1} alt="" />,
        <img className="home-banner" src={P2} alt="" />,
        <img className="home-banner" src={P3} alt="" />,
        <img className="home-banner" src={P4} alt="" />,
    ]
    return <div className="home">
        <Banner items={banners} />
        <div className="introduce">
            壳隆是一家新渠道销售公司，<br></br>
        通过自身实践，引领行业发展方向，为公众提供更好的产品和服务。<br></br>
        我们的使命是“将行业带入全新的数字经济时代”。
        </div>
        <div className="img-href">
            <div className="img-href-item" onClick={()=>window.location.href = '//'+window.location.host + '/group#a'}>
                <img src={h1} alt="" />
                <span>公司简介</span>
            </div>
            <div className="img-href-item" onClick={()=>window.location.href = '//'+window.location.host + '/group#b'}>
                <img src={h2} alt="" />
                <span>公司使命</span>
            </div>
            <div className="img-href-item" onClick={()=>window.location.href = '//'+window.location.host + '/group#c'}>
                <img src={h3} alt="" />
                <span>公司愿景</span>
            </div>
        </div>

        <div className="business-introduce">
            <div className="business-introduce-item">
                <img className="business-introduce-img" src={h4} alt="" onClick={()=>window.location.href = '//'+window.location.host + '/business/car'}/>
                <div className="business-introduce-content">
                    <div>
                        <img className="text" src={h7} alt="" /><br></br>
                    </div>
                    <p>为汽车后市场上下游企业 赋能</p>
                    <div style={{height: 20}} onClick={()=>window.location.href = '//'+window.location.host + '/business/car'}>
                    <img className="icon" src={Arrow} alt="" /></div>
                </div>
            </div>
            <div className="business-introduce-item">
                <div className="business-introduce-content">
                    <div>
                        <img className="text" src={h8} alt="" />
                    </div>
                    <p>以最新科技 提供领先健康产品</p>
                    <div style={{height: 20}} onClick={()=>window.location.href = '//'+window.location.host + '/business/health'}>
                    <img className="icon" src={Arrow} alt="" /></div>
                </div>
                <img className="business-introduce-img" src={h5} alt="" onClick={()=>window.location.href = '//'+window.location.host + '/business/health'} />
            </div>
            <div className="business-introduce-item">
                <img className="business-introduce-img" src={h6} alt="" onClick={()=>window.location.href = '//'+window.location.host + '/business/bigData'} />
                <div className="business-introduce-content">
                    <div>
                        <img className="text" src={h9} alt="" />
                    </div>
                    <p>以核心能力 支持企业数字化转型</p>
                    <div style={{height: 20}} onClick={()=>window.location.href = '//'+window.location.host + '/business/bigData'}>
                    <img className="icon" src={Arrow} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
}