import React from 'react'
import Title from '../Title'
import './detail.less'
import d1 from '../assets/d4/chellon20200406a.jpg'
import d2 from '../assets/d4/chellon20200406b.jpg'
import d3 from '../assets/d4/chellon20200406c.jpg'
import Img from '../Img'


export default props => {

    return <div className="news-detail">
        <Title name="集团人文" />
        <div className="content">
            <h1 className="title">
            车享家全国运营大会
            </h1>
            <p className="date">2021.04.06</p>
            <p className="desc"> 车享家全国运营大会于2021.4.5~4.8在上海召开，来自车享家总部及全国各地的运营负责人参加了此次会议。壳隆作为受邀嘉宾，携手壳牌共同参与了此次活动。在活动中详细介绍了即将升级的全系列产品，新增加的游戏互动环节也得到了与会全场的一致好评。</p>
            <Img src={d1} alt=""/>
            <p className="desc" style={{textAlign: 'center'}}>壳牌工程师 在介绍产品情况</p>
            <Img src={d2} alt="" /><br></br>
            <p className="desc" style={{textAlign: 'center'}}>小知识互动环节</p>
            <Img src={d3} alt="" /><br></br>
            <p className="desc" style={{textAlign: 'center'}}>壳隆车享家项目林总 与车享家及壳牌领导合影</p>
        </div>
    </div>
}