import React, { useState } from "react";


export default props => {

    const [image, setImage] = useState()

    return <div className="img-pre">
        <img src={props.src} alt="" style={props.style} onClick={()=>setImage(props.src)}/>
        {image&& <img src={image} onClick={()=>setImage(undefined)} style={{width: '80vw',position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></img>}
    </div>
}