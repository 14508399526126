import React from 'react'
import './style.less'
// import h1 from '../assets/health/h1.png'
import h1 from '../assets/12231632467647_.pic.jpg'
import h2 from '../assets/health/j2.png'
import h3 from '../assets/health/j3.png'
import h4 from '../assets/health/j4.png'

import Title from '../Title'

export default props => {

    return <div className="business-health">
         <Title name="大健康业务" />
        <div className="title"> 
        {/* <span>大健康类</span> */}
        </div>
        <div style={{ textAlign: 'center' }}>
            <img src={h1} alt="" style={{ width: 515, height: 424 }} />
        </div>
        <p style={{textAlign: "center",  }}><a href="http://www.proticcs.com/index" style={{color: '#6D7276'}}>http://www.proticcs.com</a></p>      


        <div className="business-health-introduce">
            <h2>公司介绍</h2>
            <div className="business-health-introduce-text">
                <p style={{lineHeight: 3}}>
                PROTICCS专注于健康产业，致力于为用户提供专业、健康、安全的产品和服务。
                
                与株式会社医道Medical深度合作，推出联合品牌益生菌系列产品，
                
                利用线上、线下营销渠道为产品提供销售推广及物流服务的同时，也为产品的研发提供市场反馈和销售数据支持。
                </p>
            </div>
        </div>
        <h2 style={{ marginTop: 40 }}>PROTICCS产品特点</h2>
        <div className="icons-texts">
            <div className="icon-text">
                <img src={h2} alt="" />
                <span>安全</span>
            </div>
            <div className="icon-text">
                <img src={h3} alt="" />
                <span>安心</span>
            </div>
            <div className="icon-text">
                <img src={h4} alt="" />
                <span>自然</span>
            </div>
        </div>
        <div className="content-text">
        使用的有益微生物不会对身体产生不良影响，所培养的有益微生物也皆源于自然界，可保证商品安全的使用 <br></br>
        理论源于相关医学报告(拥有科学根据，使用医学论文中所指定的素材)<br></br>
        由东京大学生命科学系的教授和准教授进行兼修，可保证商品的科学背景<br></br>
        在医疗机关得以应用于临床，虽商品本身为辅助食品，但可保证其效果 <br></br>
        对于病症改善比较全面，可期待其调整和改善各种身体不适的效果<br></br>
        </div>
        <div className="slogan">
            <p>"</p>
            <p style={{ textAlign: 'center' }}> 壳隆作为PROTICCS在中国唯一的战略合作伙伴，利用壳隆自身在中国的销售渠道和营销能力，</p>
            <p style={{ textAlign: 'center' }}>帮助PROTICCS的产品在中国推广落地，造福中国消费者的同时，也为PROTICCS产品的研发提供有价值的数据反馈。</p>
            <p style={{ textAlign: 'right' }}>"</p>
        </div>
    </div>
}
