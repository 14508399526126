import React from "react"
import './style.less'
import Title from '../Title'
import N1 from '../assets/news/n1.png'
import D2 from '../assets/d2/d2-1.jpeg'
import D3 from '../assets/d3/d3-1.jpeg'
import D4 from '../assets/d4/chellon20200406a.jpg'
import D5 from '../assets/d5/chellon20200411a.jpg'

import NewsDetail from './detail'
import NewsDetail2 from './detail2'
import NewsDetail3 from './detail3'
import NewsDetail4 from './detail4'
import NewsDetail5 from './detail5'

export const news = [
    {
        img: D2,
        title: '我司联合供应商壳牌邀请上海车享家优质服务商',
        date: '2018-06-27',
        desc: '我司联合供应商壳牌邀请上海车享家优质服务商&特约认证店代表于嘉兴乍浦参加培训&工厂参观学习之旅',
        Comp: NewsDetail2,
        path: '/newsDeatil2'
    },
    {
        img: D3,
        title: '携手上汽，汇珠成海',
        date: '2019.7.21',
        desc: '7.21日，我司联合壳牌邀请上汽荣威名爵全国经销商代表于珠海参加“携手上汽，汇珠成海”活动。',
        Comp: NewsDetail3,
        path: '/newsDeatil3'
    },
    {
        img: N1,
        title: '困境之下无数企业分崩离析，盛大汽车为什么可以…',
        date: '2020-04-18',
        desc: '4月17日，第一届盛大汽车合作商户2020春季宁波交流会顺利举办。本次交流会以“合纵联盟·盛世在宁”为主题，30多家合作商户受邀参加，盛世大联车管家副总裁钱奕志出席了本次交流会并致辞。',
        Comp: NewsDetail,
        path: '/newsDeatil1'
    },
    {
        img: D4,
        title: '车享家全国运营大会',
        date: '2021.4.06',
        desc: '车享家全国运营大会于2021.4.5~4.8在上海召开，来自车享家总部及全国各地的运营负责人参加了此次会议。',
        Comp: NewsDetail4,
        path: '/newsDeatil4'
    },
    {
        img: D5,
        title: '天猫养车郑州推介会',
        date: '2021.4.11',
        desc: '壳隆与天猫养车门店的合作，首次从上海外拓至外省。未来会有更多的落户合作城市与壳隆展开合作。',
        Comp: NewsDetail5,
        path: '/newsDeatil5'
    },
].reverse()

export default props => {

    return <div className="news">
        <Title name="集团人文" />
        {news.map((val, index) => {
            return <div className="new-item" key={index}>
                <img src={val.img} alt="" />
                <div className="news-content">
                    <h1>{val.title}</h1>
                    <p className="date">{val.date}</p>
                    <p className="desc">{val.desc}</p>
                    <div className="more">
                        <div className="more-btn" onClick={() => {
                            window.location.href = '//' + window.location.host + val.path
                        }}>查看更多{'>'} </div>
                    </div>
                </div>
            </div>
        })}
    </div>
}