import React from 'react'
import Title from '../Title'
import './detail.less'
import d1 from '../assets/d3/d3-1.jpeg'
import d2 from '../assets/d3/d3-2.jpeg'
import d3 from '../assets/d3/d3-3.jpeg'
import d4 from '../assets/d3/d3-4.jpeg'
import d5 from '../assets/d3/d3-5.jpeg'
import d6 from '../assets/d3/d3-6.jpeg'
import d7 from '../assets/d3/d3-7.jpeg'
import d8 from '../assets/d3/d3-8.jpeg'
import d9 from '../assets/d3/d3-9.jpeg'
import d10 from '../assets/d3/d3-10.jpeg'
import d11 from '../assets/d3/d3-11.jpeg'
import d12 from '../assets/d3/d3-12.jpeg'
import d13 from '../assets/d3/d3-13.jpeg'
import d14 from '../assets/d3/d3-14.jpeg'
import d15 from '../assets/d3/d3-15.jpeg'
import Img from '../Img'

export default props => {

    return <div className="news-detail">
        <Title name="集团人文" />
        <div className="content">
            <h1 className="title">
                携手上汽，汇珠成海
            </h1>
            <p className="date">7.21</p>
            <p className="desc"> 7.21日，我司联合壳牌邀请上汽荣威名爵全国经销商代表于珠海参加“携手上汽，汇珠成海”活动。</p>
            <p className="desc">   此次活动分为签到、安全培训、参观壳牌珠海工厂和晚宴四个部分。不仅是为了回馈优秀的经销商合作伙伴，也让大家更加了解壳牌润滑油从生产到出库整个流水线，让大家更贴近壳牌，了解壳牌。</p>
            <Img src={d1} alt="" /><br></br>
            <Img src={d2} alt="" /><br></br>

            <Img src={d3} alt="" /><br></br>
            <Img src={d4} alt="" /><br></br>
            <Img src={d5} alt="" /><br></br>
            <Img src={d6} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>签到场</p>
            <p className="desc">经销商代表们从全国各地飞往珠海后立刻就赶到酒店签到，所有人员到齐后即往壳牌在珠海高栏港的润滑油生产基地出发。珠海基地，作为壳牌在全球最大的润滑油生产厂，年产规模达到40万吨，总投资8300万美元。</p>
            <Img src={d7} alt="" /><br></br>
            <Img src={d8} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>图为壳牌珠海润滑油有限公司</p>
            <p className="desc">到达工厂后，所有人都认真参加了安全培训，并且听壳牌负责人讲解了亚太区润滑油供应链的整个流程，让彼此对壳牌有了更深入的了解，拉近了品牌方和经销商之间的距离。</p>
            <Img src={d9} alt="" /><br></br>
            <Img src={d10} alt="" /><br></br>
            <Img src={d11} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>图为安全培训讲解</p>
            <Img src={d12} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>更换防护鞋服和帽子</p>
            <Img src={d13} alt="" />
            <p className="desc">由于壳牌工厂严禁携带通讯电子设备入内，所以大家都积极配合遵守参观秩序，并没有拍摄润滑油生产的整个过程。不过大家都觉得参观生产线是一件有趣且不枯燥的活动，流水线的环境也是非常安全和无害的，充分证明了壳牌在生产润滑油过程中对品质和环境友好的追求。</p>
            <p className="desc">参观结束后，所有人返回酒店举行晚宴。晚宴中，壳牌合作伙伴上海壳隆贸易有限公司代表人田总和壳牌负责人张总分别发表了感谢词。感谢壳牌给予我们这次机会组织全国优秀经销商代表人来到珠海基地，近距离地了解和观察壳牌润滑油生产的整个流程；也感谢上汽荣威名爵对壳牌的大力支持和积极配合。</p>
            <Img src={d14} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>图为田总致感谢词</p>
            <p className="desc"> 本次活动圆满落幕，代表们都给予非常好的回应，希望还有机会参加类似的活动，拉近彼此的距离，也对供应商和产品有更深入的了解。此次活动不仅带动了经销商们的积极性，也让他们更加信任壳隆，更加支持壳牌，为保持良好的合作关系奠定基础。</p>
            <Img src={d15} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>图为上汽荣威名爵全国优秀经销商代表人在壳牌珠海润滑油有限公司合影</p>
        </div>
    </div>
}