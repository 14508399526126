import React from 'react'
import Title from '../Title'
import './detail.less'
import N1 from '../assets/news/n1.png'
import N2 from '../assets/news/n2.png'
import N3 from '../assets/news/n3.png'
import N4 from '../assets/news/n4.png'
import N5 from '../assets/news/n5.png'
import Img from '../Img'

export default props => {

    return <div className="news-detail">
        <Title name="集团人文" />
        <div className="content">
            <h1 className="title">
                困境之下无数企业分崩离析，盛大汽车为什么可以迎难而上
            </h1>
            <p className="date">盛世大联汽车  2020-04-18</p>
            <Img src={N1} alt="" style={{ width: '100%', height: 500 }} />
            <p className="desc">4月17日，第一届盛大汽车合作商户2020春季宁波交流会顺利举办。本次交流会以“合纵联盟·盛世在宁”为主题，30多家合作商户受邀参加，盛世大联车管家副总裁钱奕志出席了本次交流会并致辞。</p>
            <Img src={N2} alt="" />
            <p className="desc">春节至今，遭遇了疫情重创的汽车后市场仿佛经历了一次洗牌。无数商家由于资金链、人才流失、客流大幅减少等问题被迫关门，盛大汽车的合作商户们也同样陷入了生存困境。盛大作为汽车后市场的行业标杆，我们深知，当下的困境不仅仅是疫情，大环境中还充满了许多不确定性，而我们能做的就是给到商户们实实在在的帮助，带领他们直面危机、度过难关！</p>
            <Img src={N3} alt="" />
            <p className="desc">本次交流会是带着帮助合作商户们解决实际问题的初心举办的。交流会上，钱总一再强调盛大打造的是汽车后市场的服务闭环，将商户们整合到盛大的生态链中，而盛大作为这个闭环中强大稳定的支撑，足以保证这个生态链能够抵御任何风险。目前，盛大的合作商户已经超过25000家，除港澳台地区外已完成全国覆盖。</p>
            <Img src={N4} alt="" />
            <p className="desc">对于合作商户的选择，盛大本着“互利共赢、共同成长”的原则，经过严格的筛选和考察，挑选出市场上具有丰富经验和服务能力的商户。将B端大量优质客户引流到各商户，发挥双方优势，真正将线下商户整合到盛大构建的互联网场景的一站式车后服务生态圈中。</p>
            <Img src={N5} alt="" style={{ width: '588px', height: 320 }} />
            <p className="desc">目前，盛大要解决的问题，就是商户们真实遇到的问题。盛大的调研团队切实走访了全国多家商户，了解到他们面临的资金周转困难、客源流失、原材料上涨等问题。因此，本届交流会直接围绕商户经营的痛点给出了“答案”。盛大特别邀请到了有着多年汽车后市场运营经验的史国荣老师分享了主题为《引流与锁客》、《市场解析》的讲座，现场商户们积极提问，气氛推向了高潮。</p>
        </div>
    </div>
}