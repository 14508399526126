import React from 'react'
import Title from '../Title'
import './detail.less'
import d1 from '../assets/d2/d2-1.jpeg'
import d2 from '../assets/d2/d2-2.jpeg'
import d3 from '../assets/d2/d2-3.jpeg'
import d4 from '../assets/d2/d2-4.jpeg'
import d5 from '../assets/d2/d2-5.jpeg'
import d6 from '../assets/d2/d2-6.jpeg'
import d7 from '../assets/d2/d2-7.jpeg'
import Img from '../Img'

export default props => {

    return <div className="news-detail">
        <Title name="集团人文" />
        <div className="content">
            <h1 className="title">
                壳隆同行 乍浦工厂参观学习之旅
            </h1>
            <p className="date">2018年6月27日</p>
            <p className="desc"> 2018年6月27日，我司联合供应商壳牌邀请上海车享家优质服务商&特约认证店代表于嘉兴乍浦参加培训&工厂参观学习之旅。</p>
            <p className="desc"> 此次活动分为上海车享家总部集合、技术培训及参观壳牌浙江乍浦工厂。此次活动目的不仅延续了2017年“携手上汽，汇珠成海”的活动理念，也让优秀的经销商合作伙伴更贴近壳牌。</p>
            <Img src={d1} alt="" style={{ width: '100%', height: 500 }} />
            <p className="desc">活动当天，经销商代表们先于上海车享家总部集合后，同我司与壳牌代表驱车前往嘉兴乍浦杭州湾海景大酒店。来到酒店的杭州湾景厅，所有代表们认真听了壳牌的技术部高级工程师对润滑油供应链的培训。</p>
            <Img src={d2} alt="" /><br></br>
            <Img src={d3} alt="" />
            <p className="desc" style={{textAlign: 'center'}}>图为上海车享总部</p>
            <Img src={d4} alt="" /><br></br>
            <Img src={d5} alt="" /><br></br>
            <Img src={d6} alt="" />
            <p className="desc">本次活动随工厂参观后圆满落幕，所有代表们在参观浙江壳牌化工石油有限公司的工厂时井然有序。随着品牌方与服务商间联合品牌合成机油的产品不断更新升级，本次活动不仅加深服务商对品牌方的认知，也让所有参与者共享未来发展的信心。</p>
            <Img src={d7} alt="" />
        </div>
    </div>
}