import React, {createRef, useEffect, useState} from 'react'
import './style.less'


export default props => {
    const { items } = props

    const [indicator, setIndicator] = useState(1)
    const [width, setWidth] = useState(0)
    const ref = createRef()
    const [bannerStyle ,setBannerStyle] = useState()

    const setEnd = ()=>{
        setBannerStyle({transform: `translateX(0px)`})
        setIndicator(0)
    }

    useEffect(()=>{
        if(bannerStyle && bannerStyle.transform === 'translateX(0px)'){
            setIndicator(1)
        }
    }, [bannerStyle])


    useEffect(()=>{
        const interval = setInterval(() => {
            if(items.length === indicator){
                setEnd()
            }else{
                const index = indicator+1
                setIndicator(index)
                if(width){
                    setBannerStyle({transform: `translateX(-${width*index}px)`, transition: `transform 1s`})
                }
            }
        }, 4000);
        
        return ()=>{
            clearInterval(interval)
        }
    }, [indicator, width])


    useEffect(()=>{
        const index = indicator
        if(width){
            setBannerStyle({transform: `translateX(-${width*index}px)`, transition: `transform 1s`})
        }
    }, [indicator])


    useEffect(()=>{
        if(ref.current){
            setWidth(ref.current.clientWidth)
            setBannerStyle({transform: `translateX(-${ref.current.clientWidth}px)`})
        }
    }, [])

    return <div className="banner">
        <div className="banner-content" style={bannerStyle}>
            <div className="banner-item" ref={ref}>
                    {items[items.length-1]}
            </div>
            {items.map((item, index) => {
                return <div key={index} className="banner-item" ref={ref}>
                    {item}
                </div>
            })}
        </div>
            <div className="banner-index">
                {Array.from({length: items.length}, (v,k) => k).map((_val, index)=>{
                    return <div className="banner-index-item" key={index} 
                    onClick={()=>setIndicator(index+1)}
                    style={{backgroundColor: index+1 === indicator ? "#fff" : ''}}></div>
                })}
            </div>
    </div>
}