import './App.less';
import Header from './Header/index.jsx'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Footer from './Footer'
import Group from './Group'
import Business from './Business'
import News from './News/index'
import {news} from './News'

function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path="/group" component={Group} />
        <Route path="/business" component={Business} />
        <Route path="/news" component={News} />
        {news.map((item, index) => {
          return <Route path={item.path} component={item.Comp} key={index} />
        })}
        <Route path="/" component={Home} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
