import React from 'react'
import './style.less'
import Title from '../Title'
import Img1 from '../assets/group1.png'
import Img2 from '../assets/group2.png'
import Img3 from '../assets/group3.svg'
import Img4 from '../assets/group4.svg'
import Img5 from '../assets/group5.svg'
import Img6 from '../assets/group6.jpg'

export default () => {

    return <div className="group">
        <Title name='集团介绍' />
        <div className="slogan">
            <p>"</p>
            <p style={{ textAlign: 'center' }}>出生时，我们是一群选择了“困难模式”投胎的80后；</p>
            <p style={{ textAlign: 'center' }}>创业时，我们带着活力无限的90后们开启了“难上加难”模式，却乐在其中。</p>
            <p style={{ textAlign: 'right' }}>"</p>
        </div>
        <div className="group-content" style={{ marginBottom: 35 }}>
            <a name="a"></a>
            <h1>公司简介</h1>
            <div className="group-content-text">
                <p>壳隆成立于2013年，主要业务为协助汽车后市场品牌商进行门店销售服务，业务涵盖油品，化学品，易损件等。主要服务内容包括产品代理，市场活动策划，营销技能培训。</p>
                <p>壳隆2021年引入大健康业务，形成跨境、跨领域的营销服务能力。</p>
                <p>在不断完善线下渠道的同时，公司也建立起了线上平台，着力于营销和供应链的数字化，利用数字技术降低营销和物流成本的同时，构建行业数据收集、处理和利用能力，为上下游企业提供数据服务。</p>
            </div>
        </div>
        <div className="group-content">
            <a name="b"></a>
            <h1>公司使命</h1>
            <div className="group-content-mix">
                <img src={Img6} alt="" />
                <div className="group-content-text">
                    <p>通过自身实践，引领行业发展方向，为公众提供更好的产品和服务。</p>
                    <p>壳隆多年的行业经验积累以及对行业的深入理解，为创新发展打下了坚实的基础。未来，壳隆将通过不断创新，在产品和服务的研发，生产，销售等各个环节提高效率，将行业带入全新的数字经济时代。</p>
                </div>
            </div>
        </div>

        <div className="group-content">
            <h1>核心价值观</h1>
            <div className="group-content-mix">
                <div className="group-content-text" style={{ alignContent: 'center' }}>
                    <p>我们认为，肩负起社会责任是确保企业正确发展方向的基础。提供专业的产品、专业的服务是企业体现自身价值的基础。保持前瞻性是企业持续进步的基础。</p>
                </div>
                <img src={Img2} alt="" />
            </div>
        </div>

        <div className="group-content">
            <a name="c"></a>
            <h1>公司愿景</h1>
            <div className="group-content-text">
                <p style={{ margin: 0 }}>数据是继土地、资本、劳动力、技术之后的第五大生产要素。壳隆在2021年启动数字化转型，充分利用公司所处行业链的核心地位，构建数据的收集、分析处理和使用，将行业引向新的发展方向。</p>
            </div>
            <div className="group-content-imgs">
                <img src={Img3} alt="" />
                <img src={Img4} alt="" />
                <img src={Img5} alt="" />
            </div>
        </div>
    </div>
}