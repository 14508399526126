import React from 'react'
import Bg from './assets/bg.jpg'


export default props => {
    const {name} = props
    return <div className="title-text" style={{ position: 'relative' }}>
        <img src={Bg} alt="" style={{ height: 400, width: '100%'}} />
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -100%)', fontSize: 40, color: '#fff' }}>
            {name}
        </h1>
    </div>
}