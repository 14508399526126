import React from 'react'
import './style.less'
import A1 from '../assets/ai/a1.png'
import A2 from '../assets/ai/a2.png'
import A3 from '../assets/ai/a3.png'
import Title from '../Title'

export default props=>{
    const contents = [
        {
            img: A1,
            title: '汽车后市场',
            texts: ['作为多个顶级品牌的销售渠道，我们提供产品推广销售服务，产品和营销培训服务，通达全国的物流配送服务。',
            '与国内多家汽车后市场服务连锁企业合作，为2万+家汽修门店提供货源，并通过线上平台连接门店和车主，为门店和车主提供数据分析和基于数据分析结果的服务推送。']
        },
        {
            img: A2,
            title: '大健康市场',
            texts: ['公司和日本医道医药签署战略合作协议，将为医道产品的市场化提供全方位的服务支持。',
            '从销售网络的布设管理，到app制作和用户体验反馈的收集处理；从其品牌的市场推广，到售后服务体系的完善。',
            '力争使医道医药的科研成果能最大，最优，惠及国内需求人群。',
            // '在此，预祝IDO Medical 申请登陆美国纳斯达克顺遂！',
        ]
        },
        {
            img: A3,
            title: '人车生活',
            texts: ['围绕车辆选购、使用、维修保养等环节，推出自有品牌的产品，结合线上覆盖全场景的服务，提升用车体验。']
        },
    ]

    return <div className="business3">
        <Title name="大数据及AI业务" />
        <div className="title"> 
        {/* <span>汽车类</span> */}
        </div>
        {contents.map((val, index) => {
            return <div className="business-block" key={index}>
                {(index + 1) % 2 ? <img src={val.img} alt="" />: null}
                <div className="business-block-content">
                    <h1>{val.title}：</h1>
                    {val.texts.map((text, i) => <p index={i} className="business-block-text">{text}
                    </p>)}
                </div>
                {(index + 1) % 2 ===0  ? <img src={val.img} alt="" /> : null}
            </div>
        })}
    </div>
}
