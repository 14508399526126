import React from 'react'
import './style.less'
import Logo from '../assets/logo.png'
import Menu from './Menu'
import {withRouter} from 'react-router-dom'


export default withRouter((props) =>{
    return  <div className="header">
        <img className="logo" src={Logo} alt="" onClick={()=>{
            props.history.push('/')
        }}/>
        <Menu/>
    </div>
})