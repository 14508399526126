import React from 'react'
import './style.less'



export default props => {

    const subs = [
        {
            title: '关于我们',
            contents: '壳隆以质量求生存，创新求进，本着全心全意为客户服务的精神，为消费者提供高质、经典的品牌产品。'
        },
        {
            title: '联系我们',
            contents: ['info@chellon.com', '上海市黄浦区陆家浜路1295号金田商务407室']
        },
        {
            title: '免责声明',
            contents: '本声明未涉及的问题参见国家有关法律法规，以国家法律法规为准。'
        },
    ]

    return <div className="footer">
        <div className="footer-content">
            {subs.map((item, index) => {
                return <div className="footer-item" key={index}>
                    <div className="footer-title">{item.title}</div>
                    {Array.isArray(item.contents) ? item.contents.map((val, index) => <div key={index} className="footer-text">{val}</div>) : <div className="footer-text" style={{width: 200}}>{item.contents}</div>}
                </div>
            })}
        </div>
        <div className="footer-back-num" style={{margin: '0 auto', width: 310, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <a href="https://beian.miit.gov.cn" target="blank">沪ICP备17015451号-1</a> <span>|</span>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102005442" target="blank">沪公网安备31010102005442号</a>
        </div>
    </div>
}