import React from 'react'
import { Link } from 'react-router-dom'
import './menus.less'

export default props => {

    const menus = [
        {
            name: '集团介绍',
            url: '/group'
        },
        {
            name: '业务介绍',
            childs: [
                {
                    name: '汽车业务',
                    url: '/business/car'
                },
                {
                    name: '大健康业务',
                    url: '/business/health'
                },
                {
                    name: '大数据及AI业务',
                    url: '/business/bigData'
                },
                // {
                //     name: '境外服务',
                //     url: '/business/service'
                // },
            ]
        },
        // {
        //     name: '技术展示',
        //     url: ''
        // },
        {
            name: '集团人文',
            url: '/news'
        },
    ]

    return <div className="menu">
        {menus.map((item, index)=>{
            return <MenuItem key={index} path={item}/>
        })}
    </div>
}


export const MenuItem = props => {
    const { path } = props
    return <div className="menu-item">
        {
            path.childs ? <div className="menu-group-sub">
                <span className="menu-group-title">{path.name}</span>
                <div className="menu-group">
                    {path.childs.map((item, index) => {
                        return <div className="menu-group-child" key={index}><Link to={item.url}>{item.name}</Link></div>
                    })}
                </div>
            </div> : <div className="menu-single">
                <Link to={path.url}>{path.name}</Link>
            </div>
        }
    </div>
}